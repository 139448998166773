
const colorList = {
    'red': 'Красный',
    'blue': 'Синий',
    'green':'Зеленый',
    'orange':'Оранжевый',
    'yellow':'Желтый',
    'purple':'Фиолетовый',
    'gray':'Серый',
    'pink':'Розовый',
    'white':'белый'

}

export default colorList