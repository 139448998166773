

const config = {
    //URL_API:'http://localhost:8001/api/',
   // URL_API:'https://quizserver.vityazgroup.ru:8444/api/',
    URL_API:'https://quizserver.vityazgroup.ru:8444/api/',
    URL_TICKETS_GET:'ticket/get',
    URL_COLOR_GET:'color/get',
}

export default config

